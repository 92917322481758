<script setup lang="ts">
interface ColorClasses {
  bg: string;
  hover: string;
  text: string;
}

const props = defineProps<{
  to: string;
  variant?: string;
}>();
// Map variant names to their respective Tailwind CSS classes.
const variantMapping: Record<string, ColorClasses> = {
  'primary-1': { bg: 'bg-white-40', hover: 'hover:bg-white-10', text: 'text-greenish-950' },
  'secondary-1': { bg: '', hover: 'hover:text-secondary-100', text: 'text-secondary-50' },
  'secondary-2': { bg: '', hover: 'hover:text-primary-90', text: 'text-primay-100' },
};

// Default styles if the provided variant does not match any key.
const defaultVariant: ColorClasses = variantMapping['primary-1'];

// Compute the classes to be applied based on the variant prop.
const colorClasses = computed(() => {
  return props.variant && variantMapping[props.variant]
    ? variantMapping[props.variant]
    : defaultVariant;
});
</script>

<template>
    <NuxtLink
        :to="to"
        v-bind="$attrs"
        :class="[
            colorClasses.bg, 
            colorClasses.hover, 
            colorClasses.text, 
            'flex', 
            'items-center', 
            'space-x-1', 
            'rounded-md', 
            'transition',
            'duration-300',
            'ease-in-out'
        ]"
    >
        <slot />
    </NuxtLink>
</template>
